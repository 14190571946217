

















import { Component, Prop, Vue } from "vue-property-decorator";

export interface FetchValueFromRemote {
  (year: number): Promise<number>;
}

@Component({
  name: "DashboardCountBoard",
})
export default class DashboardCountBoard extends Vue {
  @Prop({ required: true }) public title!: string;

  @Prop({ required: false }) public unitName?: string;

  @Prop({ required: true }) public amount!: number;

  year: string = new Date().getFullYear().toString();

}
