



























































































































































































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator";

import {
  DataDictionaryDto,
  OrganizationUnitDto,
  OrganizationUnitDtoPagedResultDto, PointType, SelectListItem,
  VolunteerDashbordCountDto, VolunteerPointRankingDto,
  VolunteerTotalHoursRankingDto
} from "@/api/appService";

import moment from "moment";
import VolunteerDashboardCountBoard from "@/components/Dashboard/VolunteerDashboardCountBoard.vue";

@Component({
  name: "VolunteerDashboard",
  components: {
    VolunteerDashboardCountBoard,
  },
})
export default class InternalDashboard extends Vue {
  year: number | undefined = undefined;
  yearView = "";
  platformData: VolunteerDashbordCountDto = {
    /** 服务数量 */
    serviceCount: 0,

    /** 培训数量 */
    trainCount: 0,

    /** 活动数量 */
    activityCount: 0,

    /** 志愿者数量 */
    volunteerCount: 0,

    /** 志愿团体数量 */
    orgCount: 0
  };
  orgList: OrganizationUnitDto[] = [];
  orgStatisticsDataQuery: any = {
    orgId: undefined,
    beginTime: undefined,
    endTime: undefined,
    pointType: undefined,
    detailTypeId: undefined
  };
  orgStatisticsData: VolunteerDashbordCountDto = {
    /** 服务数量 */
    serviceCount: 0,

    /** 培训数量 */
    trainCount: 0,

    /** 活动数量 */
    activityCount: 0,

    /** 志愿者数量 */
    volunteerCount: 0,

    /** 志愿团体数量 */
    orgCount: 0
  };
  totalHoursRankingList: VolunteerTotalHoursRankingDto[] = [];
  yearRanking: any = [];
  pointTypeList: SelectListItem[] = [];
  detailTypeList: DataDictionaryDto[] = [];
  pointRankingList:VolunteerPointRankingDto[]=[];

  created():void {
    let date = new Date();
    const year = date.getFullYear();
    this.year = year;
    this.yearView = String(year);
    this.getPlatformData();
    this.getOrgList();
    this.fetchEnum();
    this.yearRanking = String(year);
    this.orgStatisticsDataQuery.beginTime = moment().month(moment().month())
      .startOf("month")
      .format("YYYY-MM-DD");
    this.orgStatisticsDataQuery.endTime = moment().month(moment().month())
      .endOf("month")
      .format("YYYY-MM-DD");
    this.yearRanking = [this.orgStatisticsDataQuery.beginTime, this.orgStatisticsDataQuery.endTime];
    this.getOrgStatisticsData()
  }

  //平台数据
  queryPlatformData(): void {
    if (this.yearView) {
      this.year = Number(this.yearView);
      this.getPlatformData()
    }
  }

  getPlatformData(): void {
    api.volunteerDashboard.getPlatformData({year: this.year}).then((res: VolunteerDashbordCountDto) => {
      this.platformData = res;
    })
  }

  //合作机构
  getOrgList():void {
    api.organizationUnit.getAll({maxResultCount: 65535}).then((res: OrganizationUnitDtoPagedResultDto) => {
      this.orgList = res.items!;
    })
  }

  getOrgStatisticsData():void {
    api.volunteerDashboard.getOrgStatisticsData(this.orgStatisticsDataQuery).then((res: VolunteerDashbordCountDto) => {
      this.orgStatisticsData = res;
    })
    api.volunteerDashboard.getTotalHoursRanking(this.orgStatisticsDataQuery).then((res: VolunteerTotalHoursRankingDto[]) => {
      this.totalHoursRankingList = res;
    })
    api.volunteerDashboard.getPointRanking(this.orgStatisticsDataQuery).then((res:VolunteerPointRankingDto[])=>{
      this.pointRankingList=res;
    })
  }

  queryOrgStatisticsData(): void {
    if (this.yearRanking && this.yearRanking.length === 2) {
      this.orgStatisticsDataQuery.beginTime = moment(this.yearRanking[0]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.orgStatisticsDataQuery.endTime = moment(this.yearRanking[1]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.getOrgStatisticsData();
    }
  }

  //table背景色
  tableRowClassName({row, rowIndex}: any):string {
    if (rowIndex === 0) {
      return 'table-1-row';
    } else if (rowIndex === 1) {
      return 'table-2-row';
    } else if (rowIndex === 2) {
      return 'table-3-row';
    }
    return '';
  }

  getDetailTypeList():void {
    let key = '';
    let pointType=this.orgStatisticsDataQuery.pointType;
    this.orgStatisticsDataQuery.detailTypeId=undefined;
    switch (pointType) {
      case PointType.TrainRecord:
        key = 'TrainType';
        break;
      case PointType.ServiceRecord:
        key = 'ServiceType';
        break;
      case PointType.HelpApply:
        key = '';
        break;
      case PointType.BBS:
        key = 'BbsPointType';
        break;
      case PointType.Volunteer:
        key = 'Volunteer';
        break;
      case PointType.ActivityRecord:
        key = 'ActivityType';
        break;
      default:
        break;

    }
    if (key) {
      api.dataDictionary
        .getDataDictionaryListByKey({key: key})
        .then((res) => {
          this.detailTypeList = res.items!;
        });
    } else {
      this.detailTypeList = [];
    }
  }

  fetchEnum():void {
    api.enumService
      .getValues({typeName: "PointType"})
      .then((res) => {
        this.pointTypeList = res;
      });
  }
}
