





























import { Component, Prop, Vue } from "vue-property-decorator";

export interface FetchValueFromRemote {
  (year: number): Promise<number>;
}

@Component({
  name: "DashboardCountBoard",
})
export default class DashboardCountBoard extends Vue {
  @Prop({ required: true }) public title!: string;

  @Prop({ required: false }) public unitName?: string;

  @Prop({ required: true }) public fetch!: FetchValueFromRemote;

  year: string = new Date().getFullYear().toString();

  amount = 0;

  created(): void {
    this.fetch(parseInt(this.year, 10)).then((res) => {
      this.amount = res;
    });
  }

  handleYearChange(newValue: number): void {
    this.fetch(newValue).then((res) => {
      this.amount = res;
    });
  }
}
