var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-container"},[_c('el-row',{attrs:{"gutter":50}},[_c('el-col',{attrs:{"span":6}},[_c('dashboard-count-board',{staticClass:"card-one",attrs:{"title":"年度总筹款额","fetch":_vm.fetchTotalIncome,"unit-name":"元"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var amount = ref.amount;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(amount))+" ")]}}])})],1),_c('el-col',{attrs:{"span":6}},[_c('dashboard-count-board',{staticClass:"card-two",attrs:{"title":"年度专项基金总数","fetch":_vm.fetchNewFund,"unit-name":"个"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var amount = ref.amount;
return [_vm._v(" "+_vm._s(amount))]}}])})],1),_c('el-col',{attrs:{"span":6}},[_c('dashboard-count-board',{staticClass:"card-three",attrs:{"title":"年度项目总数","fetch":_vm.fetchNewProject,"unit-name":"个"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var amount = ref.amount;
return [_vm._v(" "+_vm._s(amount)+" ")]}}])})],1),_c('el-col',{attrs:{"span":6}},[_c('dashboard-count-board',{staticClass:"card-four",attrs:{"title":"年度志愿者总数","fetch":_vm.fetchVolunteerCount,"unit-name":"人"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var amount = ref.amount;
return [_vm._v(" "+_vm._s(amount)+" ")]}}])})],1)],1),_c('el-row',{staticClass:"margin-top-xl"},[_c('el-col',{attrs:{"span":24}},[_c('el-card',[_c('div',{staticClass:"header",attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"header-title"},[_vm._v("专项基金排行")]),_c('div',{staticClass:"search-btns"},[_c('el-button',{attrs:{"plain":""},on:{"click":_vm.rankingThisMonth}},[_vm._v("本月")]),_c('el-button',{staticClass:"margin-right-sm",staticStyle:{"margin-right":"10px"},attrs:{"plain":""},on:{"click":_vm.rankingThisYear}},[_vm._v("全年")]),_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},on:{"change":_vm.changeRankTime},model:{value:(_vm.yearRanking),callback:function ($$v) {_vm.yearRanking=$$v},expression:"yearRanking"}})],1)]),_c('el-row',[_c('el-radio-group',{staticStyle:{"margin-bottom":"30px"},on:{"change":_vm.changeFundType},model:{value:(_vm.fundType),callback:function ($$v) {_vm.fundType=$$v},expression:"fundType"}},[_c('el-radio-button',{attrs:{"label":"Professional"}},[_vm._v("企业冠名")])],1)],1),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":8}},[_c('el-card',{staticClass:"card-height",attrs:{"shadow":"never"}},[_c('div',{staticClass:"text-bold"},[_vm._v("专项基金收入排行")]),_vm._l((_vm.incomingList),function(item,index){return _c('div',{key:index,staticClass:"ranking-list margin-top-xl"},[_c('div',{staticClass:"ranking"},[_c('div',{class:[
                      'margin-right-sm',
                      index < 3 ? 'num-top' : 'num' ]},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"name margin-right-sm"},[_vm._v(_vm._s(item.fundName))])]),_c('div',[_vm._v(_vm._s(item.moneyTotal)+"元")])])})],2)],1),_c('el-col',{attrs:{"span":8}},[_c('el-card',{staticClass:"card-height",attrs:{"shadow":"never"}},[_c('div',{staticClass:"text-bold"},[_vm._v("专项基金支出排行")]),_vm._l((_vm.appropriateList),function(item,index){return _c('div',{key:index,staticClass:"ranking-list margin-top-xl"},[_c('div',{staticClass:"ranking"},[_c('div',{class:[
                      'margin-right-sm',
                      index < 3 ? 'num-top' : 'num' ]},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"name margin-right-sm"},[_vm._v(_vm._s(item.fundName))])]),_c('div',[_vm._v(_vm._s(item.moneyTotal)+"元")])])})],2)],1),_c('el-col',{attrs:{"span":8}},[_c('el-card',{staticClass:"card-height",attrs:{"shadow":"never"}},[_c('div',{staticClass:"text-bold"},[_vm._v("专项基金账户余额排行")]),_vm._l((_vm.balanceList),function(item,index){return _c('div',{key:index,staticClass:"ranking-list margin-top-xl"},[_c('div',{staticClass:"ranking"},[_c('div',{class:[
                      'margin-right-sm',
                      index < 3 ? 'num-top' : 'num' ]},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"name margin-right-sm"},[_vm._v(_vm._s(item.fundName))])]),_c('div',[_vm._v(_vm._s(item.moneyTotal)+"元")])])})],2)],1)],1)],1)],1)],1),_c('el-row',{staticClass:"margin-top-xl"},[_c('el-col',{attrs:{"span":24}},[_c('el-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header"},[_c('span',{staticClass:"header-title"},[_vm._v("项目统计")])])]},proxy:true}])},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":8}},[_c('el-card',{staticClass:"card-height",attrs:{"shadow":"never"}},[_c('div',{staticClass:"text-bold"},[_vm._v("各项目当月筹款排行")]),_vm._l((_vm.fundProjectIncomeList),function(item,index){return _c('div',{key:index,staticClass:"ranking-list margin-top-xl"},[_c('div',{staticClass:"ranking"},[_c('div',{class:[
                      'margin-right-sm',
                      index < 3 ? 'num-top' : 'num' ]},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"name margin-right-sm"},[_vm._v(" "+_vm._s(item.projectName)+" ")])]),_c('div',[_vm._v(_vm._s(item.moneyTotal)+"元")])])})],2)],1),_c('el-col',{attrs:{"span":8}},[_c('el-card',{staticClass:"card-height",attrs:{"shadow":"never"}},[_c('div',{staticClass:"text-bold"},[_vm._v("项目领域占比")]),_c('div',{ref:"echartProject",staticClass:"pcChina",style:({ width: '100%', height: '500px' })})])],1),_c('el-col',{attrs:{"span":8}},[_c('el-card',{staticClass:"card-height",attrs:{"shadow":"never"}},[_c('div',{staticClass:"text-bold"},[_vm._v("当月志愿信息")]),_c('div',{staticClass:"volunteer margin-top-xl"},[_c('div',{staticClass:"volunteer-item"},[_c('div',{staticClass:"dot dot-blue"}),_c('div',[_vm._v("月度发布志愿者活动")])]),_c('div',{staticClass:"margin-left-xl margin-top-xl text-xxl"},[_vm._v(" "+_vm._s(_vm.activityCount)+" 次 ")])]),_c('div',{staticClass:"volunteer margin-top-xl"},[_c('div',{staticClass:"volunteer-item"},[_c('div',{staticClass:"dot dot-green"}),_c('div',[_vm._v("月度新增志愿者人数")])]),_c('div',{staticClass:"margin-left-xl margin-top-xl text-xxl"},[_vm._v(" "+_vm._s(_vm.volunteerCount)+" 次 ")])]),_c('div',{staticClass:"volunteer margin-top-xl"},[_c('div',{staticClass:"volunteer-item"},[_c('div',{staticClass:"dot dot-red"}),_c('div',[_vm._v("月度参与活动的志愿者人数")])]),_c('div',{staticClass:"margin-left-xl margin-top-xl text-xxl"},[_vm._v(" "+_vm._s(_vm.applyCount)+" 次 ")])])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }