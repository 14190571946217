
















































































































































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import {
  DataDictionaryDto,
  FundType,
  InternalMicrocosmicDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";

import echarts from "echarts";
import "echarts/lib/chart/map";
import "echarts/map/js/china.js";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import moment from "moment";
import DashboardCountBoard from "@/components/Dashboard/DashboardCountBoard.vue";

@Component({
  name: "InternalDashboard",
  components: {
    DashboardCountBoard,
    PagedTableView,
  },
  filters: {
    formatWorkflowStep(status: string, list: any[]) {
      let result = "";
      if (list && list.length > 0) {
        list.map((item) => {
          if (item.value == status) {
            result = item.text;
            return;
          }
        });
      }
      return result;
    },
  },
})
export default class InternalDashboard extends Vue {
  activeName = "first";
  yearView = "";

  totalIncomeYear = "";
  totalIncomeByYear = 0;
  newFundYear = "";
  newFundByYear = 0;
  newProjectYear = "";
  newProjectByYear = 0;
  newVolunteerYear = "";
  newVolunteerByYear = 0;

  fundType: FundType = FundType.Professional;
  yearRanking: any = [];
  rankingStart = "";
  rankingEnd = "";
  incomingList: any = [];
  appropriateList: any = [];
  balanceList: any = [];
  fundProjectIncomeList: any = [];

  activityCount = 0;
  volunteerCount = 0;
  applyCount = 0;

  yearLine: any = [];
  lineStart = "";
  lineEnd = "";

  projectTypeList: DataDictionaryDto[] = [];
  unitTypeList: DataDictionaryDto[] = [];
  microData: InternalMicrocosmicDto[] = [];

  queryData = {
    projectType: "",
    year: "",
    status: "",
    maxResultCount: 1000,
  };

  yearData: number[] = [];
  projectCounts: number[] = [];
  activityCounts: number[] = [];
  actualBeneficiaryCounts: number[] = [];
  actualExpenses: number[] = [];
  totalBudgets: number[] = [];
  townCounts: number[] = [];

  pcCount: number[] = [];
  mobileCount: number[] = [];
  wechatCount: number[] = [];
  date: string[] = [];

  optionProject: any = {
    title: {
      text: "项目领域种类:18种",
      left: "center",
      top: "10%",
      fontSize: 26,
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "bottom",
    },
    // graphic: {
    //   type: 'text',
    //   left: 'center',
    //   top: 'center',
    //   style: {
    //     text: 'xxx',
    //     fontSize: '28'
    //   }
    // },
    series: [
      {
        name: "访问来源",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 5,
        },
        label: {
          show: false,
          position: "center",
        },
        labelLine: {
          show: false,
        },
        data: [],
      },
    ],
  };

  option: any = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["PC官网", "微官网", "小程序"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: this.yearData,
    },
    yAxis: [
      {
        type: "value",
        name: "数量",
        position: "left",
        axisLabel: {
          formatter: "{value}",
        },
      },
    ],
    series: [
      {
        name: "PC官网",
        type: "line",
        //stack: "总量",
        //yAxisIndex: 1,
        data: this.pcCount,
      },
      {
        name: "微官网",
        type: "line",
        //stack: "总量",
        //yAxisIndex: 1,
        data: this.mobileCount,
      },
      {
        name: "小程序",
        type: "line",
        //stack: "总量",
        //yAxis: 1,
        data: this.wechatCount,
      },
    ],
  };

  handleSearch() {
    this.getMicrocosmics();
  }
  handleReset() {
    this.queryData = {
      projectType: "",
      year: "",
      status: "",
      maxResultCount: 1000,
    };
    this.getMicrocosmics();
  }

  created() {
    let date = new Date();
    const year = date.getFullYear();
    this.yearRanking =
      this.newVolunteerYear =
      this.newProjectYear =
      this.newFundYear =
      this.totalIncomeYear =
        String(year);

    // 当月开始和结束日期
    this.lineStart = this.rankingStart = moment()
      .month(moment().month())
      .startOf("month")
      .format("YYYY-MM-DD");
    this.lineEnd = this.rankingEnd = moment()
      .month(moment().month())
      .endOf("month")
      .format("YYYY-MM-DD");
    this.yearRanking = [this.rankingStart, this.rankingEnd];
    this.yearLine = [this.lineStart, this.lineEnd];
    this.getFundIncomeRecordRank();
    this.getFundAppropriateRecordRank();
    this.getFundBalanceRecordRank();

    this.getFundProjectIncomeRank();

    this.getActivityApplyCountByCurrentMonth();
    this.getNewVolunteerByCurrentMonth();
    this.getNewPublishedActivityCountByCurrentMonth();

    // 饼图
    this.getPieData();
    this.getLineData();
  }

  /**
   * 年度总筹款额
   * @param year
   */
  fetchTotalIncome(year: number): Promise<number> {
    return api.dashboard.getTotalIncomeByYear({ year });
  }

  /**
   * 年度专项基金总数
   * @param year
   */
  fetchNewFund(year: number): Promise<number> {
    return api.dashboard.getNewFundByYear({ year });
  }

  fetchNewProject(year: number): Promise<number> {
    return api.dashboard.getNewFundProjectByYear({ year });
  }

  fetchVolunteerCount(year: number): Promise<number> {
    return api.dashboard.getNewVolunteerByYear({ year });
  }

  // 本月
  rankingThisMonth(): void {
    // 当月开始和结束日期
    this.rankingStart = moment()
      .month(moment().month())
      .startOf("month")
      .format("YYYY-MM-DD");
    this.rankingEnd = moment()
      .month(moment().month())
      .endOf("month")
      .format("YYYY-MM-DD");
    this.yearRanking = [this.rankingStart, this.rankingEnd];
    this.getFundIncomeRecordRank();
    this.getFundAppropriateRecordRank();
    this.getFundBalanceRecordRank();
  }

  // 本年
  rankingThisYear() {
    // 当年开始和结束日期
    this.rankingStart = moment()
      .month(moment().month())
      .startOf("year")
      .format("YYYY-MM-DD");
    this.rankingEnd = moment()
      .month(moment().month())
      .endOf("year")
      .format("YYYY-MM-DD");
    this.yearRanking = [this.rankingStart, this.rankingEnd];
    this.getFundIncomeRecordRank();
    this.getFundAppropriateRecordRank();
    this.getFundBalanceRecordRank();
  }

  changeFundType(type: any) {
    if (type === "Professional") {
      this.fundType = FundType.Professional;
    }

    this.getFundIncomeRecordRank();
    this.getFundAppropriateRecordRank();
    this.getFundBalanceRecordRank();
  }

  changeRankTime() {
    if (this.yearRanking && this.yearRanking.length === 2) {
      this.rankingStart = moment(this.yearRanking[0]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.rankingEnd = moment(this.yearRanking[1]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.getFundIncomeRecordRank();
    }
  }

  changeLineTime() {
    if (this.yearLine && this.yearLine.length === 2) {
      this.lineStart = moment(this.yearLine[0]).format("YYYY-MM-DD HH:mm:ss");
      this.lineEnd = moment(this.yearLine[1]).format("YYYY-MM-DD HH:mm:ss");
      this.getLineData();
    }
  }

  // 饼图-项目领域占比
  getPieData() {
    api.dashboard.getProjectCategoryChart().then((res) => {
      this.optionProject.series[0].data = res;
      const echartProject = echarts.init(
        this.$refs.echartProject as HTMLDivElement
      );
      echartProject.setOption(this.optionProject);
    });
  }

  // 折线图-站点访问次数
  getLineData() {
    api.dashboard
      .getAccessRecord({
        start: this.lineStart,
        end: this.lineEnd,
      })
      .then((res) => {
        if (res) {
          this.option.xAxis.data = res.pcData ? res.pcData.dateArray : [];
          this.option.series[0].data = res.pcData ? res.pcData.countArray : [];
          this.option.series[1].data = res.mobileData
            ? res.mobileData.countArray
            : [];
          this.option.series[2].data = res.wechatData
            ? res.wechatData.countArray
            : [];

          const myChart = echarts.init(this.$refs.echartGeo as HTMLDivElement);
          myChart.setOption(this.option);
        }
      });
  }

  // 专项基金收入排行
  getFundIncomeRecordRank() {
    if (this.rankingStart && this.rankingEnd) {
      if (this.fundType) {
        api.dashboard
          .getFundIncomeRecordRank({
            start: this.rankingStart,
            end: this.rankingEnd,
            fundType: this.fundType,
          })
          .then((res) => {
            this.incomingList = res;
          });
      } else {
        api.dashboard
          .getFundIncomeRecordRank({
            start: this.rankingStart,
            end: this.rankingEnd,
          })
          .then((res) => {
            this.incomingList = res;
          });
      }
    }
  }
  // 专项基金支出排行
  getFundAppropriateRecordRank() {
    if (this.rankingStart && this.rankingEnd) {
      if (this.fundType) {
        api.dashboard
          .getFundAppropriateRecordRank({
            start: this.rankingStart,
            end: this.rankingEnd,
            fundType: this.fundType,
          })
          .then((res) => {
            this.appropriateList = res;
          });
      } else {
        api.dashboard
          .getFundAppropriateRecordRank({
            start: this.rankingStart,
            end: this.rankingEnd,
          })
          .then((res) => {
            this.appropriateList = res;
          });
      }
    }
  }
  // 基金账户余额排行
  getFundBalanceRecordRank() {
    if (this.rankingStart && this.rankingEnd) {
      if (this.fundType) {
        api.dashboard
          .getFundBalanceRecordRank({
            start: this.rankingStart,
            end: this.rankingEnd,
            fundType: this.fundType,
          })
          .then((res) => {
            this.balanceList = res;
          });
      } else {
        api.dashboard
          .getFundBalanceRecordRank({
            start: this.rankingStart,
            end: this.rankingEnd,
          })
          .then((res) => {
            this.balanceList = res;
          });
      }
    }
  }
  // 各项目当月筹款排行
  getFundProjectIncomeRank() {
    api.dashboard.getFundProjectIncomeRank().then((res) => {
      this.fundProjectIncomeList = res;
    });
  }
  // 月度发布志愿者活动
  getNewPublishedActivityCountByCurrentMonth() {
    api.dashboard.getNewPublishedActivityCountByCurrentMonth().then((res) => {
      this.activityCount = res;
    });
  }
  // 月度新增志愿者人数
  getNewVolunteerByCurrentMonth() {
    api.dashboard.getNewVolunteerByCurrentMonth().then((res) => {
      this.volunteerCount = res;
    });
  }
  // 月度参与活动的志愿者人数
  getActivityApplyCountByCurrentMonth() {
    api.dashboard.getActivityApplyCountByCurrentMonth().then((res) => {
      this.applyCount = res;
    });
  }

  initDefaultData() {
    api.enumService.getValues({ typeName: "FundType" }).then((res) => {
      this.fundTypes = res!;
    });
  }

  fetchEnum() {
    api.enumService
      .getValues({ typeName: "WorkflowOfProjectByInternal" })
      .then((res) => {
        this.workflowStepList = res;
      });
  }
}
