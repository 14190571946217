<template>
  <section>
    <img width="100%" src="@/assets/images/welcome.jpeg" />
  </section>
</template>

<script>
export default {
  name: "welecome"
}
</script>

<style scoped>

</style>
